
/*!
 * Basic
 */

 .placeholder { color: #aaa; }

body,
html {
    width: 100%;
    height: 100%;
    font-size: 14px;
    font-weight: 300;
    font-family: "faricy-new-web",Helvetica,Arial,sans-serif;
    line-height: 1.4em;
}

body,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "faricy-new-web",Helvetica,Arial,sans-serif;
    color: #111111;
}

ul, li, p, .panel-body p, .panel-body {
    font-size: 16px;
    font-weight: 300;
}

.inverse {
    color: white;
    text-decoration: underline;
}

.h1, h1 {
    font-size: 40px;
    font-weight: 700;
}

.h2, h2 {
    font-size: 30px;
    font-weight: 700;
}

.h3, h3 {
    font-size: 20px;
    font-weight: 400;
}

.h4, h4 {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 5px;
}

a {
  color: black;
}

label {
    color: #747474;
}

ol, ul {
    padding-left: 20px;
}

.page {
    padding-top: 50px;
}

.list-group-item.active, .list-group-item.active:focus, .list-group-item.active:hover {
  background-color: black;
  border-color: black;
}
.btn-primary {
  background-color: black;
  border-color: black;
}
.btn-success {
  box-shadow: 2px 3px 0px 0px rgba(104,171,71,0.3);
}

.topnav {
    font-size: 13px;
}

.navbar-default {
    background-color: rgba(255,255,255,0.9);
    background-color: #ffffff;
}

.navbar-brand {
  height: 95px;
  padding: 0;
}

.navbar-register {
    float: right;
    margin-top: 22px;
    margin-right: 10px;
}

.navbar-brand img {
    max-width: 250px;
    height: 80px;
}

.navbar-default .navbar-toggle {
    border-color: black;
    padding: 6px 10px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
}
.navbar-default .navbar-toggle .icon-bar {
  background-color: black;
}
.navbar-default .navbar-nav>li>a {
  color: black;
}

.navbar-default .navbar-nav>li>a:focus, .navbar-default .navbar-nav>li>a:hover {
    background-color: black;
}

.navbar-default .navbar-nav>li>a.btn {
    color: #ffffff;
    padding: 6px 15px;
    margin-left: 10px;
}

.navbar-default .navbar-nav>li>a.btn-success:hover {
    background-color:#449d44;
    border-color:#398439;
    color: #ffffff;
}

.navbar-default .navbar-nav>li>a.btn {
  margin-top: -8px;
}

.image-bg-fluid-height,
.image-bg-fixed-height {
    text-align: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
    width: 100%;
}


.intro-header {
    padding-top: 50px;
    padding-bottom: 0;
    text-align: center;
    color: #f8f8f8;
    background-size: cover;
}

.intro-header h2 {
    font-weight: 300;
}

.lead {
    font-size: 20px;
    margin-top: 0;
    font-weight: 400;
}

.btn-group-xl>.btn, .btn-xl {
    padding: 16px 25px;
    font-size: 20px;
    line-height: 1.3333333;
    border-radius: 8px;
}

.intro-message {
    position: relative;
}

.intro-message > h1 {
    margin: 0;
    text-shadow: 2px 2px 3px rgba(0,0,0,0.6);
    font-size: 40px;
}

.intro-divider {
    width: 400px;
    border-top: 2px solid black;
    border-bottom: 2px solid rgba(0,0,0,0.2);
}

.intro-message > h3 {
    text-shadow: 2px 2px 3px rgba(0,0,0,0.6);
}

.panel-title {
  font-size: 18px;
  font-weight: 400;
}

.network-name {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 2px;
}

.content-section-a {
    padding: 50px 0;
    background-color: #f8f8f8;
}

.content-section-b {
    padding: 50px 0;
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
}

.content-section-form {
    padding: 100px 0 0 0;
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
}

#intro {
    /*background: url(/templates/basic/images/bg-intro.jpg) no-repeat center center;
    background-color: #ffffff;
    background-size: cover;*/
    text-align: center;
    color: #ffffff;
    padding: 80px 0;
}

#intro h1 {
    margin: 1em 0 .2em 0;
    font-size: 2em;
    text-shadow: 2px 2px 3px rgba(0,0,0,0.3);
    color: #ffffff;
    font-weight: 400;
}

#intro h2, #intro .lead {
    font-weight: 400;
    margin-top: 0;
    text-shadow: 2px 2px 3px rgba(0,0,0,0.3);
    color: #ffffff;
    font-size: 25px;
}

#intro h2 {
    font-size: 35px;
    font-weight: 600;
}

#intro p {
    font-size: 1.6em;
    margin-top: 20px;
    text-shadow: 2px 2px 3px rgba(0,0,0,0.3);
    font-weight: 700;
    color: #ffffff;
}

#intro p small {
    font-weight: 400;
    font-size: 0.9em;
    color: #ffffff;
}

.section-Feature {
    padding: 50px 0;
}

.section-Intro, .section-Feature {
    color: #ffffff;
    text-align: center;
    background: rgba(0,0,0,0.3);
}

.section-Intro h1, .section-Intro h2, .section-Intro h3, .section-Intro h4, .section-Intro h5, .section-Intro h1 small, .section-Intro h2 small, .section-Intro h3 small, .section-Intro h4 small, .section-Intro h5 small,
.section-Feature h1, .section-Feature h2, .section-Feature h3, .section-Feature h4, .section-Feature h5, .section-Feature h1 small, .section-Feature h2 small, .section-Feature h3 small, .section-Feature h4 small, .section-Feature h5 small {
    color: #ffffff;
}

.section-Intro h3, .section-Feature h3 {
    font-weight: 400;
    font-size: 22px;
    color: #ffffff;
}

.section-Intro p, .section-Feature p {
    font-size: 15px;
    color: #ffffff;
}

.section-Intro p.lead, .section-Feature p.lead {
    font-size: 15px;
}

.section-Transport {
    background: rgba(255,255,255,0.8);
}

.section-Download {
    padding: 50px 0;
    background-color: #f8f8f8;
}

.section-Accommodation {
    background-image: url(/templates/bigthanks2015/images/accommodation-bg.jpg);
    background-size: cover;
}

.section-heading {
    margin-bottom: 30px;
}

.section-heading-spacer {
    */float: left;
    width: 200px;
    border-top: 3px solid rgba(0,0,0,0.3);;
}

.banner {
    padding: 100px 0;
    color: #f8f8f8;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.banner h2 {
    margin: 0;
    text-shadow: 2px 2px 3px rgba(0,0,0,0.6);
    font-size: 30px;
}

.banner ul {
    margin-bottom: 0;
}

.banner hr {
    margin-top: 0;
}

.banner-social-buttons {
    float: right;
    margin-top: 0;
}

.google-maps {
    position: relative;
    padding-bottom: 75%; // This is the aspect ratio
    height: 0;
    overflow: hidden;
}
.google-maps iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
}

.panel-heading .progress {
  background-color: #ffffff;
  margin-bottom: 0;
}

.list-inline>li {
  display: inline-block;
}

footer {
    padding: 20px 0;
    color: white;
    background-color: #222;
    font-size: 12px;
}

footer a {
    color: white;
}

footer a:hover, footer a:active {
    color: #e5e5e5;
}

footer ul.list-inline li::after {
    content: "  |  ";
}

footer ul.list-inline li:last-child::after {
    content: "";
}

footer ul.list-inline li a {
    padding-right: 10px;
}

footer .text-muted {
  color: #e5e5e5;
  font-weight: 300;
}

p.copyright {
    margin: 15px 0 0;
}

.loading {
    min-height: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    opacity: 0.2;
}

.ts-logo {
    max-width: 200px;
}


[v-cloak] {
    display: none; !important
}

.fadein {
    -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 2s; /* Firefox < 16 */
        -ms-animation: fadein 2s; /* Internet Explorer */
         -o-animation: fadein 2s; /* Opera < 12.1 */
            animation: fadein 2s;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0 0 .5em 0;
    line-height: 26px;
}

h1 {
    margin: 2em 0 1em 0;
    font-size: 30px;
    font-weight: 700;
}

.section-Intro h1 {
    margin-top: 0;
}

h2 {
    font-size: 30px;
    font-weight: 700;
}

h3 {
    font-size: 20px;
    font-weight: 400;
}

h4 {
    font-size: 16px;
    font-weight: 400;
}

.img-responsive {
    margin-bottom: 12px;
}

.spacer::before {
    /*content: "";
    width: 200px;
    margin: 0 0 40px 0;
    border-top: 3px solid rgba(0,0,0,0.3);;
    display: block;*/
}


section {
    padding: 50px 0 50px;
}

.content {
    padding-top: 95px;
}

.map {
    padding: 8px;
    border: 1px solid #dddddd;
    border-radius: 5px;
}

.map iframe {
    border-radius: 5px;
    display: block;
}

.btn-primary {
  background-color: black;
  border-color: black;
}

.btn-primary:hover {
    color: #fff;
    background-color: black;
    border-color: black;
}

.navbar-toggle {
    margin-top: 22px;
}

.navbar-default .navbar-nav>li>a {
    color: black;
}

.navbar-default .navbar-nav>li>a.btn {
    color: #ffffff;
    padding: 6px 15px;
    margin-left: 10px;
}
.navbar-default .navbar-nav>li>a.btn-success:hover {
    background-color:#449d44;
    border-color: #398439;
    color: #ffffff;
}

.terms-and-conditions {
    font-size: 14px;
    padding: 12px;
    border: 1px solid #ccc;
    overflow-y: scroll;
    height: 200px;
    border-radius: 4px;
}

.img-center {
    margin-left: auto;
    margin-right: auto;
}

.navbar {
    min-height: 85px;
}

.navbar-brand {
    height: 60px;
}

.navbar-brand img {
    max-width: 200px;
    height: 64px;
    margin-top: 8px;
    margin-left: 15px;
}

.form-section {
    display: none;
    margin-bottom: 30px;
}

.form-section.active {
    display: block;
}


label {
    font-size: 1.1em;
}

.form-group-large label {
    font-size: 20px;
    font-weight: 700;
}


.form-group-large label.attending {
    font-size: 18px;
    margin: 10px 0 5px 0;
}

.form-group-large .checkbox-inline, .form-group-large .radio-inline {
    font-size: 1.1em;
    font-weight: 400;
}

.form-group-large input[type=checkbox], .form-group-large input[type=radio] {
    margin-top: 3px;
}

.help-block {
    font-size: 0.9em;
    line-height: 1.4em;
    color: #909090;
}

@media (max-width: 468px) {

    .navbar-default .navbar-collapse { border: none;}

    .navbar-brand img {
        max-width: 150px;
        height: 47px;
        margin-top: 15px;
        margin-left: 15px;
    }

    .navbar-default .navbar-collapse .navbar-nav>li>a.btn { display: inline-block; padding: 5px 8px;}

    .hide-mobile {
        display: none;
    }

    body,
    html {
        font-size: 13px;
    }

    #intro h1 {
        font-size: 1.5em;
    }

    #intro h2, #intro .lead {
        font-size: 1.8em;
    }

    #intro p {
        font-size: 1.4em;
    }

    .navbar-default .navbar-nav>li>a.btn {
        margin-top: 0;
    }

    .banner {
        padding: 10px 0;
    }

    footer {
        padding: 20px 0;
    }

    .btn-success {
        margin-bottom: 20px;
    }

    p.copyright {
      margin: 15px 0;
    }

    .lead {
        font-size: 18px;
        margin-top: 20px;
        font-weight: 400;
    }

    .content {
        padding-top: 85px;
    }

    .content-section-form {
      padding: 50px 0 0 0;
    }

    .section-heading {
        margin-bottom: 10px;
    }

    .navbar-default .navbar-nav>li>a.btn {
        margin-left: 0;
        margin-right: 20px;
    }

}



@media (min-width: 768px) {

    .navbar-register {
        display: none;
    }

    .navbar {
        min-height: 95px;
    }

    .navbar-right {
        margin-top: 10px;
    }

    .navbar-brand img {
        max-width: 250px;
        height: 80px;
        margin-top: 5px;
        margin-left: 0;
    }

    .navbar-nav>li>a {
        padding: 32px 8px 0 8px;
        font-size: 14px;
    }

    .navbar-default .navbar-nav>li>a.btn {
        padding: 10px 8px;
        margin-top: 20px;

    }

}

@media (min-width: 998px) {

    .navbar {
        min-height: 95px;
    }

    .navbar-right {
        margin-top: 0;
    }

    .navbar-right {
        float: right!important;
    }
}

@media (min-width: 1200px) {

    .navbar {
        min-height: 95px;
    }

    .navbar-right {
        margin-top: 0;
    }

    .navbar-nav>li>a {
        padding: 38px 8px 0 8px; /*padding: 32px 15px 0 15px;*/
        /* font-size: 16px; */
    }
    .navbar-right {
        float: right!important;
    }
}


/* Big Devices, Wide Screens */
@media only screen and (min-width : 1400px) {

    .container {
        width: 1350px;
    }

}

/* animations (vuejs) */

.expand-transition {
  transition: all .3s ease;
  height: 30px;
  padding: 10px;
  background-color: #eee;
  overflow: hidden;
}
.expand-enter, .expand-leave {
  height: 0;
  padding: 0 10px;
  opacity: 0;
}

.bounce-enter {
  animation: bounce-in .5s;
}
.bounce-leave {
  /*animation: bounce-out .5s;*/
}
@keyframes bounce-in {
  0% {
    transform: scale(0.5);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes bounce-out {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(0);
  }
}


.masonry-gallery {
    -webkit-column-count: 3; /* Chrome, Safari, Opera */
    -moz-column-count: 3; /* Firefox */
    column-count: 3;
}   
 

.masonry-gallery img {
    width: 100%;
    padding: 7px 0;
}

@media (max-width: 1200px) {
        
    .masonry-gallery {
        -webkit-column-count: 3; /* Chrome, Safari, Opera */
        -moz-column-count: 3; /* Firefox */
        column-count: 3;
    }

}

@media (max-width: 768px) {
        
    .masonry-gallery {
        -webkit-column-count: 2; /* Chrome, Safari, Opera */
        -moz-column-count: 2; /* Firefox */
        column-count: 2;
    }

}

@media (max-width: 468px) {
        
    .masonry-gallery {
        -webkit-column-count: 1; /* Chrome, Safari, Opera */
        -moz-column-count: 1; /* Firefox */
        column-count: 1;
    }

}

.navbar-default .navbar-nav>.open>a {
    background: none;
}


.file-input {
  position: relative;
  overflow: hidden;
  margin: 0px;
  color: #333;
  background-color: #fff;
  border-color: #ccc; }

.file-input input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0); }

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control, .input-group-addon {
  background-color: #D0DEDF; }

  
.dropdown-menu {
    min-width: 260px;
}

.dropdown-toggle {
    margin-bottom: 20px;
}

.linkedin {
    background-image: url('/templates/basic/images/linkedin.png');
    background-repeat: no-repeat;
    background-size: 53px 14px;
    background-position: center center;
    padding: 2px 10px;
    text-indent: -9999px;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: inline-block;
    transition: border-color .3s;
}

.linkedin:hover {
    border-color: #1573a1;
}

.panel-profile {
    min-height: 300px;
    margin-bottom: 50px;
    position: relative;
}

.panel-profile .panel-heading {
    background: none;
    height: 105px;
}

.panel-profile .photo-container {
    width: 120px;
    height: 120px;
    margin-left: -60px;
    border: 3px solid rgba(0,0,0,0.2);
    border-radius: 60px;
    white-space: nowrap;
    vertical-align: middle;
    display: inline-block;
    position: absolute;
    top: -30px;
    left: 50%;
    z-index: 1001;
}

.panel-profile .badge {
    background-clip: padding-box;
    width: 120px;
    height: 120px;
    margin-left: -60px;
    font-size: 3em;
    line-height: 110px;
    border: 3px solid rgba(0,0,0,0.2);
    border-radius: 60px;
    position: absolute;
    top: -30px;
    left: 50%;
}

.panel-profile h3 {
    margin-bottom: .2em;
}

.panel-profile h4 {
    margin-bottom: 1em;
    color: #555555;
}

.panel-profile .btn {
    margin-left: 5px;
    margin-right: 5px;
}



@media (max-width: 992px) {
    .panel-profile {
        min-height: 300px;
    }

    .panel-profile .btn-linkedin {
        margin-bottom: 5px !important;
    }
}

/* stripe */

/**
 * The CSS shown here will not be introduced in the Quickstart guide, but shows
 * how you can use CSS to style your Element's container.
 */

.panel-payments {
    padding: 20px 15px;
}

.StripeElement {
  background-color: white;
  height: 40px;
  padding: 10px 12px;
  border-radius: 4px;
  border: 1px solid transparent;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.pop {
    font-size: 1.3em;
    margin-top: 0.2em;
}

.date-countdown {
    margin: 0 auto;
    text-align: center;
    text-transform: uppercase;
    color: #999999;
}

.date-countdown div {
    width: 100px;
    padding: 20px 0 10px 0;
    margin: 0 5px;
    display: inline-block;
    border: 2px solid #cccccc;
    border-radius: 10px;
}

.date-countdown span {
    margin-bottom: 10px;
    font-family: monospace;
    font-size: 3em;
    font-weight: 600;
    color: #333333;
    display: block;
}

.date-countdown-to {
    margin-top: 10px;
    font-weight: 600;
    text-align: center;
    color: #999999;
}

.date-countdown-to span {
    font-weight: 400;
}

.agenda-content > p:last-child {
    margin: 0;
}